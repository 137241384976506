const theme = {
  fonts: {
    main: 'CircularStd',
  },
  colors: {
    main: '#009900',
    black: '#000000',
    black2: '#1a1a1a',
    black3: '#151515',
    black4: '#111111',
    white: '#ffffff',
    gray1: '#737373',
    gray2: '#fbfaf9',
    gray3: '#f1f0ee',
    gray4: '#f4f3f2',
    gray5: '#f2f2f2',
    gray6: '#fefefe',
    gray7: '#d6d6d6',
    gray8: '#e8e8e8',
    grayLine: 'rgba(0,0,0,0.5)',
    red: 'red',
    pink: 'pink',
  },
};

export default theme;
