import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SectionWrap = styled.div`
  position: relative;
  display: ${({ nonGrid }) => (nonGrid ? 'block' : 'grid')};
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: repeat(2, auto);
  width: 100%;
  padding-top: ${({ first }) => (first ? '156px' : '0')};

  ${({ site }) =>
    site &&
    `
    padding: 20rem 12rem 0 10rem;
  `};

  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 33.3333%);
  }

  @media screen and (max-width: 767px) {
    display: ${({ nonFlex }) => (nonFlex ? 'block' : 'flex')};
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: ${({ first }) => (first ? '120px' : '0')};
  }
`;

const Section = ({ children, className, first, nonGrid, site, nonFlex }) => (
  <SectionWrap
    className={className}
    first={first}
    nonGrid={nonGrid}
    site={site}
    nonFlex={nonFlex}
  >
    {children}
  </SectionWrap>
);

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  first: PropTypes.bool,
  className: PropTypes.string,
  nonGrid: PropTypes.bool,
  site: PropTypes.bool,
};

Section.defaultProps = {
  first: false,
  className: '',
  nonGrid: false,
  site: false,
};

export default Section;
