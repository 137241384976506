import styled from 'styled-components/macro';

const ParagraphShort = styled.p`
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 3rem;
    color: ${({theme}) => theme.colors.black};
    opacity: 0.7;
    
    @media screen and (max-width: 767px){
      font-size: 16px;
      line-height: 27px;
      font-weight: 400;
    }
`;

export default ParagraphShort;