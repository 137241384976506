import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  justify-content: flex-end;
  width: ${({ width }) => width};
  height: ${({ height }) => `${height}`};
  min-height: ${({ height }) => `${height}`};
  overflow: visible;
  grid-row: ${({ gridRow }) => gridRow};
  grid-column: ${({ gridColumn }) => gridColumn};
  & img {
    height: 100%;
    width: 100%;
  }

  ${({ padding }) =>
    padding &&
    `
    padding-right: 4.9rem;
    padding-left: 4.9rem;
 `};

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const GridBlock = ({
  children,
  className,
  height,
  width,
  gridRow,
  gridColumn,
  padding,
  horizontal,
}) => (
  <Wrap
    className={className}
    height={height}
    width={width}
    gridRow={gridRow}
    gridColumn={gridColumn}
    padding={padding}
    horizontal={horizontal}
  >
    {children}
  </Wrap>
);

GridBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  padding: PropTypes.bool,
  horizontal: PropTypes.bool,
};

GridBlock.defaultProps = {
  className: '',
  height: 'auto',
  width: '100%',
  padding: false,
  horizontal: false,
};

export default GridBlock;
