import React from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';
import { fadeIn, fadeOut } from '../../../../animations/animations';

const FadeLink = ({ children, ...props }) => (
  <TransitionLink
    entry={{
      trigger: ({ entry, node }) => fadeIn(node, entry),
      delay: 0.3,
      length: 0.5,
    }}
    exit={{
      length: 0.3,
      trigger: ({ exit, node }) => fadeOut(node, exit),
    }}
    {...props}
  >
    {children}
  </TransitionLink>
);

FadeLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]).isRequired,
};

export default FadeLink;
