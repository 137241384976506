import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import ParagraphShort from '../text/ParagraphShort';

const StyledParagraphShort = styled(ParagraphShort)`
  margin-bottom: 4rem;
  font-size: 1.3rem;
  background-color: pink;
  color: red;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
`;

const InputWrap = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;
const StyledInput = styled.input`
  position: relative;
  border: none;
  background-color: transparent;
  width: 100%;
  margin-bottom: 1rem;

  ${({ as }) =>
    as &&
    css`
      min-height: 15rem;
      resize: none;
      ::placeholder {
        transform: translateY(13.2rem);
      }
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray1};
  }
  &:focus {
    outline: none;
  }
`;

const Input = props => (
  <>
    <InputWrap>
      {!props.textarea && <StyledInput {...props} />}
      {props.textarea && <StyledInput as="textarea" {...props} />}
    </InputWrap>
    <StyledParagraphShort>{props.errorMessage}</StyledParagraphShort>
  </>
);

Input.propTypes = {
  textarea: PropTypes.bool,
};

Input.defaultProps = {
  textarea: false,
};
export default Input;
