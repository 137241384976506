import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import contact from '../../../../assets/images/navigations/contact.svg';
import logo from '../../../../assets/images/navigations/makadu_logo.svg';
import Burger from '../Burger/Burger';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fade from 'react-reveal/Fade';
import FadeLink from '../FadeLink/FadeLink';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const StyledList = styled.ul`
  position: fixed;
  right: -25%;
  top: 0;
  width: 25%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray3};
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateX(${({ open }) => (open ? '-100%' : 0)});
  transition: transform 0.6s;

  li {
    position: relative;
    padding: 0 2rem;
    &:hover svg {
      transform: translate(2rem, -50%);
      opacity: 1;
    }
    &:hover a {
      transform: translateX(2rem);
    }
    svg {
      position: absolute;
      top: 50%;
      left: -1rem;
      opacity: 0;
      transition: transform 0.3s, opacity 0.2s 0.1s;
      transform: translate(0, -50%);
    }
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
  a {
    font-size: 2rem;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    transition: transform 0.3s;
    opacity: 0.7;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    right: -100%;
  }
  @media screen and (max-width: 767px) {
    li {
      padding: 0 0 0 2rem;
    }
    a {
      font-size: 2.8rem;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2.9rem;
  background-color: ${({ theme }) => theme.colors.white};
  @media screen and (max-width: 767px) {
    padding: 25px 21px;
  }
`;

const NavBtnsWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const NavBtn = styled.a`
  width: 2.7rem;
  height: 2.7rem;
  background: ${({ img }) => `url('${img}') center center / cover no-repeat`};
  margin-left: 4.7rem;
  transition: 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 767px) {
    width: 23px;
    height: 23px;
  }
`;

const StyledPhoneLink = styled.a`
  padding-left: 2rem;
  margin-top: 5rem;
  :hover {
    color: ${({ theme }) => theme.colors.main};
  }
`;

const StyledLogoImage = styled.img`
  width: 160px;
  height: 100%;
`;

const animation = {
  delay: 400,
  gap: 50,
  duration: 700,
  distance: '30rem',
};

const checkIfOutside = (ref, callback) => {
  useEffect(() => {
    document.addEventListener('mousedown', e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    });
    return () => {
      document.removeEventListener('mousedown', e => {
        if (ref.current && !ref.current.contains(e.target)) {
          callback();
        }
      });
    };
  }, [ref]);
};

const Navigation = ({ isLinkToTop }) => {
  const wrapper = useRef(null);

  const [open, setOpen] = useState(false);
  const handleToggleNav = () => {
    setOpen(prevState => !prevState);
  };

  const handleCloseNav = () => {
    setOpen(false);
  };

  checkIfOutside(wrapper, handleCloseNav);

  return (
    <Wrap ref={wrapper}>
      <StyledList open={open}>
        <div>
          <Fade
            right
            delay={animation.delay}
            when={open}
            distance={animation.distance}
            duration={animation.duration}
          >
            <li>
              <FontAwesomeIcon icon={faChevronRight} />
              <FadeLink
                to="/co-robimy/strony-internetowe"
                onClick={handleToggleNav}
              >
                Strony internetowe
              </FadeLink>
            </li>
          </Fade>
          <Fade
            right
            delay={animation.delay + animation.gap}
            when={open}
            distance={animation.distance}
            duration={animation.duration}
          >
            <li>
              <FontAwesomeIcon icon={faChevronRight} />
              <FadeLink
                onClick={handleToggleNav}
                to="/co-robimy/sklepy-internetowe"
              >
                Sklepy internetowe
              </FadeLink>
            </li>
          </Fade>
          <Fade
            right
            delay={animation.delay + 2 * animation.gap}
            when={open}
            distance={animation.distance}
            duration={animation.duration}
          >
            <li>
              <FontAwesomeIcon icon={faChevronRight} />
              <FadeLink
                onClick={handleToggleNav}
                to="/co-robimy/projektowanie-graficzne"
              >
                Projektowanie graficzne
              </FadeLink>
            </li>
          </Fade>
          <Fade
            right
            delay={animation.delay + 3 * animation.gap}
            when={open}
            distance={animation.distance}
            duration={animation.duration}
          >
            <li>
              <FontAwesomeIcon icon={faChevronRight} />
              <FadeLink
                onClick={handleToggleNav}
                to="/co-robimy/dedykowane-aplikacje-i-systemy"
              >
                Dedykowane aplikacje i systemy
              </FadeLink>
            </li>
          </Fade>
          <Fade
            right
            delay={animation.delay + 4 * animation.gap}
            when={open}
            distance={animation.distance}
            duration={animation.duration}
          >
            <StyledPhoneLink href="tel:+48 605 540 644">
              +48 605 540 644
            </StyledPhoneLink>
          </Fade>
        </div>
      </StyledList>
      {isLinkToTop ? (
        <AnchorLink to="/#mainPage">
          <StyledLogoImage src={logo} />
        </AnchorLink>
      ) : (
        <FadeLink to="/">
          <StyledLogoImage src={logo} />
        </FadeLink>
      )}

      <NavBtnsWrap>
        <NavBtn href="mailto: biuro@makadu.pl" img={contact} />
        <Burger onClick={handleToggleNav} open={open} />
      </NavBtnsWrap>
    </Wrap>
  );
};

export default Navigation;
