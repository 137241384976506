import styled from 'styled-components/macro';
import ParagraphShort from './ParagraphShort';

const ParagraphInfo = styled(ParagraphShort)`
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1.6rem;
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

export default ParagraphInfo;
