import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
    ${normalize}

    *, *::before, *::after{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    @font-face {
        font-family: "CircularStd";
        src: url("../assets/fonts/Circular Std.otf")   format("otf");
        font-weight: bold;
    }
    
    //@font-face {
    //    font-family: "CircularStd";
    //    src: url("../assets/fonts/CircularStd-Black.woff")   format("woff");
    //    font-weight: bold;
    //} 
    //
    //@font-face {
    //    font-family: "CircularStd";
    //    src: url("../assets/fonts/CircularStd-Bold.woff")   format("woff");
    //    font-weight: bold;
    //
    //} 
    //
    //@font-face {
    //    font-family: "CircularStd";
    //    src: url("../assets/fonts/CircularStd-Book.woff")   format("woff");
    //    font-weight: bold;
    //
    //} 

    *::selection{
       background: ${({ theme }) => theme.colors.main};
    }
    
    *:hover,
    *:focus{
      outline-color: ${({ theme }) => theme.colors.main};
    }

    html {
        font-size: 62.5%;
        font-family: ${({ theme }) => `${theme.fonts.main}, sans-serif`};
        
        @media screen and (min-width: 1600px){
            font-size: 66%;
        }
        
        @media screen and (min-width: 1850px){
            font-size: 69%;
        }
        
        @media screen and (min-width: 2200px){
            font-size: 90%;
        }
        
        @media screen and (max-width: 1366px){
            font-size: 55%;
        }
        
        @media screen and (max-width: 1280px){
            font-size: 50%;
        }
    }

    body {
        font-size: 14px;
        font-size: 1.4rem;
        //height: 200vh;
    }

    h1 {
        font-size: 24px;
        font-size: 2.4rem;
    }

    .wrap{
        padding-left: 10%;
        padding-right: 10%;
    }
`;

export default GlobalStyle;
