import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 30px;
  height: 30px;
  margin-left: 4.7rem;
  overflow: hidden;
  cursor: pointer;
  padding: 6px;
`;

const Beam = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black};
  transition: transform 0.3s 0.3s ease, opacity 0.3s 0.3s;
  &:first-child {
    transform: translateY(${({ open }) => (open ? '8px' : 0)})
      rotate(${({ open }) => (open ? '45deg' : '0')});
  }
  &:nth-child(2) {
    width: 55%;
    opacity: ${({ open }) => (open ? 0 : 1)};
  }
  &:last-child {
    transform: translateY(${({ open }) => (open ? '-8px' : 0)})
      rotate(${({ open }) => (open ? '-45deg' : '0')});
  }
`;

const Burger = ({ open, ...props }) => (
  <Wrap {...props}>
    <Beam open={open} />
    <Beam open={open} />
    <Beam open={open} />
  </Wrap>
);

Burger.propTypes = {
  className: PropTypes.string,
};

Burger.defaultProps = {
  className: '',
};

export default Burger;
