import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import Section from '../../../shared/wraps/Section/Section';
import Block from '../../../shared/wraps/Block/Block';
import GridBlock from '../../../shared/wraps/GridBlock/GridBlock';
import BigTitle from '../../../shared/text/BigTitle';
import ParagraphShort from '../../../shared/text/ParagraphShort';
import Footer from '../../../shared/Footer/Footer';
import ParagraphInfo from '../../../shared/text/ParagraphInfo';
import ContactForm from '../../../shared/ContactForm/ContactForm';
import SiteBackground from '../../../shared/SiteBackground/SiteBackground';

const StyledSection = styled(Section)`
  .rightParagraphBlock {
    grid-row: 1/2;
    grid-column: 4/5;
  }

  .contactBlock {
    grid-row: 2/3;
    grid-column: 1/3;
  }

  @media screen and (max-width: 991px) {
    .rightParagraphBlock {
      grid-row: 1/2;
      grid-column: 3/4;
      p {
        margin-bottom: 20px;
      }
    }

    .contactBlock {
      grid-row: 2/4;
      grid-column: 1/3;
    }
  }

  @media screen and (max-width: 767px) {
    .rightParagraphBlock {
      grid-row: 1/2;
      grid-column: 3/4;
      p {
        margin-bottom: 52px;
      }
    }
  }
`;

const StyledBigTitle = styled(BigTitle)`
  margin-top: 15.1rem;
  margin-bottom: 21rem;
  grid-row: 1/2;
  grid-column: 2/4;
  @media screen and (max-width: 991px) {
    grid-column: 1/4;
  }

  @media screen and (max-width: 767px) {
    margin-top: 71px;
    margin-bottom: 30px;
  }
`;

const StyledParagraph = styled(ParagraphShort)`
  margin-bottom: 10.8rem;
  @media screen and (max-width: 767px) {
    text-align: center;
    margin-bottom: 52px;
  }
`;

const Email = styled(ParagraphInfo)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray1};
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const InfoWrap = styled.div`
  margin-bottom: 5rem;
  ${({ mTop }) =>
    mTop &&
    css`
      margin-top: 5rem;
    `}
`;

const AdressBlock = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, 50%);
  grid-row: 2/3;
  grid-column: 3/5;

  .mailBlock {
    grid-row: 1/2;
    grid-column: 1/2;
    justify-content: flex-start;
  }

  .adressBlock {
    grid-row: 1/2;
    grid-column: 2/3;
  }

  @media screen and (max-width: 991px) {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, 1fr);
    grid-row: 4/5;
    grid-column: 1/4;

    .mailBlock,
    .adressBlock {
      padding-top: 40px;
    }

    .mailBlock {
      grid-row: 1/2;
      grid-column: 1/2;
    }

    .adressBlock {
      grid-row: 1/2;
      grid-column: 2/3;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    .adressBlock {
      padding-top: 0;
    }
  }
`;

const Contact = ({ anchorLinkId }) => (
  <StyledSection>
    <SiteBackground />
    <StyledBigTitle className="bigTitle">Hello</StyledBigTitle>
    <GridBlock className="rightParagraphBlock" padding>
      <StyledParagraph>
        Naprawdę na tym etapie nie musisz się zastanawiać. Zadzwoń lub przyjedź.
      </StyledParagraph>
    </GridBlock>
    <GridBlock className="contactBlock">
      <ContactForm />
    </GridBlock>
    <AdressBlock>
      <GridBlock className="mailBlock">
        <Block padding>
          <InfoWrap mTop>
            <ParagraphInfo>+48605540644</ParagraphInfo>
            <Email as="a" href="mailto: biuro@makadu.pl">
              biuro@makadu.pl
            </Email>
          </InfoWrap>
        </Block>
      </GridBlock>
      <GridBlock className="adressBlock">
        <Block padding>
          <InfoWrap>
            <ParagraphInfo>Pl/ Bernardyński 1/10</ParagraphInfo>
            <ParagraphInfo>61-844 Poznań</ParagraphInfo>
          </InfoWrap>
          <InfoWrap>
            <ParagraphInfo>Godziny otwarcia:</ParagraphInfo>
            <ParagraphInfo>Pon-Pt: 10:00 - 18:00</ParagraphInfo>
          </InfoWrap>
        </Block>
      </GridBlock>
      <Footer anchorLinkId={anchorLinkId} />
    </AdressBlock>
  </StyledSection>
);

export default Contact;
