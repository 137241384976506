import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const onScrollAnimation = (
  itemToAnimate,
  animationId,
  itemToPin,
  scrollTriggerId,
) => {
  gsap.registerPlugin(ScrollTrigger);

  gsap.config({
    force3D: false,
  });

  return gsap.fromTo(
    itemToAnimate,
    { x: -400 },
    {
      id: animationId,
      x: 0,
      ease: 'Power0.easeNone',
      scrollTrigger: {
        trigger: itemToPin,
        scrub: true,
        start: 'top 70%',
        end: '50% 50%',
        id: scrollTriggerId,
      },
    },
  );
};

export const fadeIn = (node, params) => {
  const { delay, length } = params;
  gsap.fromTo(
    node,
    { autoAlpha: 0 },
    {
      duration: length,
      delay,
      ease: 'power1.out',
      autoAlpha: 1,
    },
  );
};

export const fadeOut = (node, params) => {
  const { length } = params;

  gsap.fromTo(
    node,
    { autoAlpha: 1 },
    {
      duration: length,
      ease: 'power1.out',
      autoAlpha: 0,
    },
  );
};
