import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components/macro';
import theme from '../styles/theme';
import GlobalStyle from '../styles/GlobalStyle';
import Navigation from '../components/shared/navigations/Navigation/Navigation';
import Contact from '../components/Sites/Home/Contact/Contact';
import { TransitionPortal } from 'gatsby-plugin-transition-link';

const Layout = ({
  children,
  className,
  nonBgc,
  showIntro,
  isLinkToTop,
  id,
  path,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <span id={id} />
      <div className={className}>
        {!showIntro ? (
          <TransitionPortal>
            <Navigation isLinkToTop={isLinkToTop} />
          </TransitionPortal>
        ) : (
          <Navigation />
        )}

        <GlobalStyle />
        {children}
        <Contact nonBgc={nonBgc} anchorLinkId={`${path}#${id}`} />
      </div>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  nonBgc: PropTypes.bool,
  showIntro: PropTypes.bool,
  isLinkToTop: PropTypes.bool,
};

Layout.defaultProps = {
  className: '',
  nonBgc: false,
  showIntro: false,
  isLinkToTop: false,
};

export default Layout;
