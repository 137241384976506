import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const SiteWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const BgLines = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const BackgroundColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({bgcAllSite}) => bgcAllSite ? '100%' : '50%'};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray2};
  
  ${({bgcRight}) => bgcRight && `
        left: unset;
        right: 0;
  `};
  
  ${({nonBgc}) => nonBgc && `
        background-color: transparent;
  `};
  
  @media screen and (max-width: 991px){
    display: none;
  }
`;

const Line = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: ${({ lineLeft }) => `calc(${lineLeft}% - 1px)`};
  width: 2px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray4};
  
  @media screen and (max-width: 991px){
    left: 33.333%;
    &:nth-child(2){
      left: 66.666%;
    }
    &:nth-child(3){
      display: none;
    }
  }
  
  @media screen and (max-width: 767px){
    left: 20px;
    &:nth-child(2){
      left: unset;
      right: 20px;
    }
  }
`;

const SiteBackground = ({bgcAllSite, bgcRight, nonBgc}) => (
  <SiteWrap>
    <BgLines>
        <BackgroundColor bgcAllSite={bgcAllSite} bgcRight={bgcRight} nonBgc={nonBgc}/>
      <Line lineLeft={25} />
      <Line lineLeft={50} />
      <Line lineLeft={75} />
    </BgLines>
  </SiteWrap>
);

SiteBackground.propTypes = {
    bgcAllSite: PropTypes.bool,
    bgcRight: PropTypes.bool,
    nonBgc: PropTypes.bool,
};

SiteBackground.defaultProps = {
    bgcAllSite: false,
    bgcRight: false,
    nonBgc: false,
}
export default SiteBackground;
