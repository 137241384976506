import styled from 'styled-components/macro';

const ColorBox = styled.div`
  text-align: center;
  width: 100%;
  padding: 4.3rem 4.5rem;
  background-color: ${({ theme }) => theme.colors.main};
  transition: 0.3s ease;
  :hover {
    background-color: ${({ theme }) => theme.colors.white};
    p,
    a {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    padding: 30px 20px;
  }
`;

export default ColorBox;
