import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const BlockWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyStart }) =>
    justifyStart ? 'flex-start' : 'flex-end'};
  flex-wrap: wrap;
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  ${({ horizontal }) =>
    horizontal &&
    `
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
 `}

  ${({ padding }) =>
    padding &&
    css`
      padding-right: 4.9rem;
      padding-left: 4.9rem;
      @media screen and (max-width: 767px) {
        padding-right: 2rem;
        padding-left: 2rem;
      }
    `};

  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Block = ({ children, horizontal, width, padding, height }) => (
  <BlockWrap
    height={height}
    horizontal={horizontal}
    width={width}
    padding={padding}
  >
    {children}
  </BlockWrap>
);

Block.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  horizontal: PropTypes.bool,
  padding: PropTypes.bool,
  height: PropTypes.string,
};

Block.defaultTypes = {
  className: '',
  horizontal: false,
  width: '100%',
  padding: false,
  height: '100%',
};

export default Block;
