import React from 'react';
import styled from 'styled-components/macro';
import ParagraphShort from '../text/ParagraphShort';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const FooterWrap = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4.3rem 5.5rem;
  grid-row: 2/3;
  grid-column: 2/3;

  @media screen and (max-width: 991px) {
    grid-column: 1/4;
  }

  @media screen and (max-width: 767px) {
    padding: 22px;
    width: 100%;
  }
`;

const StyledParagraph = styled(ParagraphShort)`
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 400;

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  color: black;
  opacity: 0.7;
`;

const Footer = ({ anchorLinkId }) => {
  return (
    <FooterWrap>
      <StyledParagraph>@2020 Makadu. All Rights Reserved.</StyledParagraph>
      <StyledAnchorLink to={anchorLinkId}>
        <FontAwesomeIcon icon={faChevronUp} />
      </StyledAnchorLink>
    </FooterWrap>
  );
};

export default Footer;
